import {http} from 'src/services/http'
import {SEARCH_RESULTS_LIMIT} from 'src/constants/search'
import {AxiosResponse, CancelToken} from 'axios'
import {
  CollaboratorType,
  SearchPropertyListType,
  PropertyConnectionType,
  PropertyConnectionFormOptionsType,
} from 'src/types/property'
import {OrderType, SearchPropertiesParamsType} from 'src/types/search'
import {SlugType} from 'src/constants/slug'

export async function getAllPropertiesApi(
  params: {
    order?: OrderType
    order_by?: string
    view_type?: 'all' | 'citq'
    page: number
    user_id?: number | null
    name?: string | null
    tag_ids?: number[] | null
    status?: string | null
    slug?: string | null //'mc' | 'cr' | 'cr&mc' | null
  },
  cancelToken?: CancelToken,
) {
  const data = await http.request({
    method: 'post',
    url: '/properties',
    data: {
      limit: SEARCH_RESULTS_LIMIT,
      ...params,
    },
    cancelToken,
  })

  return data
}

export async function publicGetSearchPropertiesApi(
  params: SearchPropertiesParamsType,
  cancelToken?: CancelToken,
) {
  const data = await http.request({
    method: 'post',
    url: '/public/search/properties',
    data: {
      limit: SEARCH_RESULTS_LIMIT,
      ...params,
    },
    cancelToken,
  })

  return data as AxiosResponse<SearchPropertyListType>
}

export async function publishPropertyApi(propertyId: number) {
  const data = await http.put(`/property/${propertyId}/publish`)

  return data
}

export async function toggleActivePropertyApi(propertyId: number) {
  const data = await http.put(`/property/${propertyId}/activate`)

  return data
}

export async function adminToggleHidePropertyApi(propertyId: number) {
  const data = await http.put(`/admin/property/${propertyId}/hide`)

  return data
}

export async function removePropertyApi(propertyId: number) {
  const data = await http.delete(`/property/${propertyId}`)

  return data
}

export async function publicGetRecommendedPropertiesApi(
  propertyId: number,
  photosLimit: number | null = null,
  imageMaxWidth: number | null = null,
) {
  const {data} = await http.get(
    `/public/property/id/${propertyId}/recommended_list`,
    {
      params: {
        image_max_width: imageMaxWidth,
        photos_limit: photosLimit,
      },
    },
  )
  return data
}

export async function publicGetRecommendedPropertiesByHandleApi(
  propertyHandle: string,
  photosLimit: number | null = null,
  imageMaxWidth: number | null = null,
) {
  const {data} = await http.get(
    `/public/property/handle/${propertyHandle}/recommended_list`,
    {
      params: {
        image_max_width: imageMaxWidth,
        photos_limit: photosLimit,
      },
    },
  )
  return data
}

export async function publicGetBookingPeriodsApi(propertyId: number) {
  const {data} = await http.get(
    `/public/property/${propertyId}/booking_periods`,
  )

  return data
}

export async function getCompleteBookingPeriodsApi(propertyId: number) {
  const {data} = await http.get(
    `/property/${propertyId}/complete_booking_periods`,
  )

  return data
}

export async function adminUpdatePropertySlugsApi(
  propertyId: number,
  slugs: any,
) {
  const {data} = await http.put(`/admin/property/${propertyId}/slugs`, {slugs})

  return data
}

export async function publicGetPropertiesForHomePageApi(
  photosLimit: number | null = null,
  imageMaxWidth: number | null = null,
  limit: number | null = null,
) {
  const {data} = await http.get(`/public/properties/homepage`, {
    params: {
      image_max_width: imageMaxWidth,
      photos_limit: photosLimit,
      limit,
    },
  })

  return data
}

export async function adminGetFeaturablePropertiesApi(
  slug: SlugType,
  search: string | null,
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    method: 'get',
    url: '/admin/properties/featurable_properties',
    cancelToken,
    params: {
      slug: slug,
      search: search,
    },
  })

  return data
}

export async function getFeaturedPropertiesApi(
  slug: SlugType,
  search: string | null,
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    method: 'get',
    url: '/properties/homepage',
    cancelToken,
    params: {
      slug: slug,
      search: search,
    },
  })

  return data
}

export async function addToAllFeaturedPropertiesApi(slug: SlugType) {
  const {data} = await http.post(`/properties/homepage/add_all`, {slug})

  return data
}

export async function addToFeaturedPropertiesApi(
  propertyId: number,
  order: number,
  slug: SlugType,
) {
  const {data} = await http.post(`/properties/homepage/${propertyId}`, {
    slug,
    order,
  })

  return data
}

export async function removeAllFeaturedPropertiesApi(slug: SlugType) {
  const {data} = await http.post(`/properties/homepage/remove_all`, {
    slug,
  })

  return data
}

export async function removeFromFeaturedPropertiesApi(id: number) {
  const {data} = await http.delete(`/properties/homepage/${id}`)

  return data
}

export async function updateFeaturedPropertiesOrdersApi(body: object) {
  const data = await http.put(`/properties/homepage/orders`, body)

  return data
}

export async function setUseSamePhotosSlugApi(
  propertyId: number,
  slug: SlugType | null,
) {
  const {data} = await http.put(
    `/property/${propertyId}/set_use_same_photos_slug`,
    {
      slug,
    },
  )

  return data
}

export async function adminSetBreadcrumbsApi(
  propertyId: number,
  breadcrumbs: string[],
) {
  const {data} = await http.post(`/admin/property/${propertyId}/breadcrumbs`, {
    breadcrumbs,
  })

  return data
}

export async function adminRemoveBreadcrumbsApi(propertyId: number) {
  const {data} = await http.delete(`/admin/property/${propertyId}/breadcrumbs`)

  return data
}

export async function addCollaboratorApi(
  propertyId: number,
  collaborator: CollaboratorType,
) {
  const {data} = await http.post(
    `/property/${propertyId}/collaborator`,
    collaborator,
  )

  return data
}

export async function editCollaboratorApi(
  collaboratorId: number,
  collaborator: CollaboratorType,
) {
  const {data} = await http.put(
    `/property_collaborator/${collaboratorId}`,
    collaborator,
  )

  return data
}

export async function getCollaboratorsApi(propertyId: number) {
  const {data} = await http.get(`/property/${propertyId}/collaborators`)

  return data
}

export async function removeCollaboratorApi(collaboratorId: number) {
  const {data} = await http.delete(`/property_collaborator/${collaboratorId}`)

  return data
}

export async function publicGetLoadMorePhotosApi(
  propertyId: number,
  currentIndex: number,
  count = 5,
  imageMaxWidth = 1024,
) {
  const {data} = await http.get(`/public/property/${propertyId}/photos`, {
    params: {
      index: currentIndex,
      count,
      image_max_width: imageMaxWidth,
    },
  })

  return data
}

export async function adminUpdatePropertyBookNowUrlApi(
  propertyId: number,
  shouldRedirectForBooking: boolean,
  bookNowUrl: string | null,
) {
  const {data} = await http.put(`/admin/property/${propertyId}/book_now_link`, {
    should_redirect_for_booking: shouldRedirectForBooking,
    book_now_url: bookNowUrl,
  })
  return data
}

export async function adminUpdatePropertyBookDisplayApi(
  propertyId: number,
  reviewDisplay: boolean,
  calendarDisplay: boolean,
  guestsDisplay: boolean,
) {
  const {data} = await http.put(`/admin/property/${propertyId}/book_display`, {
    review_display: reviewDisplay,
    calendar_display: calendarDisplay,
    guests_display: guestsDisplay,
  })
  return data
}

export async function getPropertyBookingCountApi(propertyId: number) {
  const {data} = await http.get(`/property/${propertyId}/booking_count`)

  return data
}

export async function adminSendPropertyNotificationApi(
  propertyId: number,
  notification: 'add-banking-information' | 'listing-incomplete',
) {
  const {data} = await http.post(
    `/admin/notifications/property/${propertyId}`,
    {
      notification,
    },
  )

  return data as {success: boolean}
}

export async function adminSendPropertyAction(propertyId: number, action: '') {
  const {data} = await http.post(`/admin/actions/property/${propertyId}`, {
    action,
  })

  return data as {success: boolean}
}

export async function adminUpdatePartialPropertyApi(
  propertyId: number,
  body: {
    priority_order?: number
    use_admin_pricelabs?: boolean
    use_pricelabs?: boolean
    guest_service_fee?: number
    host_service_fee?: number
    trust_level?: 'normal' | 'high'
  },
) {
  const {data} = await http.put(`/admin/property/${propertyId}/partial`, body)
  return data
}

export async function getPropertyConnectionsApi(propertyId: number) {
  const {data} = await http.get(`/property/${propertyId}/connections`)

  return data as PropertyConnectionType[]
}

export async function getPropertyConnectionApi(
  propertyId: number,
  connectionId: number,
) {
  const {data} = await http.get(
    `/property/${propertyId}/connections/${connectionId}`,
  )

  return data as PropertyConnectionType
}

export async function getPropertyConnectionOptionsApi(
  propertyId: number,
  connectionId: number,
) {
  const {data} = await http.get(
    `/property/${propertyId}/connections/${connectionId}/options`,
  )

  return data as PropertyConnectionFormOptionsType
}
export async function createPropertyConnectionApi(
  propertyId: number,
  formData: Partial<PropertyConnectionType>,
) {
  const {data} = await http.post(
    `/property/${propertyId}/connections`,
    formData,
  )

  return data as PropertyConnectionType
}

export async function updatePropertyConnectionApi(
  propertyId: number,
  connectionId: number,
  formData: Partial<PropertyConnectionType>,
) {
  const {data} = await http.put(
    `/property/${propertyId}/connections/${connectionId}`,
    formData,
  )

  return data as PropertyConnectionType
}

export async function syncPropertyConnectionApi(
  propertyId: number,
  connectionId: number,
) {
  const {data} = await http.post(
    `/property/${propertyId}/connections/${connectionId}/sync`,
  )

  return data as {success: boolean}
}

export async function deletePropertyConnectionApi(
  propertyId: number,
  connectionId: number,
) {
  const {data} = await http.delete(
    `/property/${propertyId}/connections/${connectionId}`,
  )

  return data
}
